import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";

export const HorizontalBar = () => {
  return (
    <div
      style={{
        height: "5px",
        width: "40px",
        backgroundColor: "#95FF0F",
        borderRadius: "20px",
      }}
    ></div>
  );
};

export const CustomNewButton = ({ text, ...props }) => {
  return (
    <button
      style={{
        borderRadius: "6px",
        color: "#134468",
        border: "none",
        backgroundColor: "#0FFF9A",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "18px 33px",
        fontSize: "16px",
        fontWeight: "600",
        alignItems: "center",
      }}
      {...props}
    >
      <div>{text}</div>
      <FaArrowRightLong size="24px" />
    </button>
  );
};
